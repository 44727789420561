body {
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings:
      "wdth" 100;
  }


.tradingview{
    height:500px;
}
.card-header.main{
    background-image: url('../assets/images/btcbg_854.png');
    background-repeat: no-repeat;
    background-position-x: 0px ;
    background-position-y: -145px;
    background-color: black;
    height:130px;
    color:#fcc02a;
}

.card-header.main div{
    display: flex; justify-content: left; align-items: center; height: 130px;
 }

.card-header.main.small{
    height:60px;
    background-position-y: -145px;
    color:#fcc02a;
}

.card.widget .card-header{
    font-size: 13px;
    background-image: url('../assets/images/btcbg_854.png');
    color:#fcc02a;
}
.error{
    color:red;
}
.navbar-brand{
    color:#fcc02a;
    font-size: 24px;
    font-weight: 600;
}