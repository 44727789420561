body {
  font-optical-sizing: auto;
  font-variation-settings: "wdth" 100;
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
}

.tradingview {
  height: 500px;
}

.card-header.main {
  color: #fcc02a;
  background-color: #000;
  background-image: url("btcbg_854.ad3d06a1.png");
  background-position: 0 -145px;
  background-repeat: no-repeat;
  height: 130px;
}

.card-header.main div {
  justify-content: left;
  align-items: center;
  height: 130px;
  display: flex;
}

.card-header.main.small {
  color: #fcc02a;
  background-position-y: -145px;
  height: 60px;
}

.card.widget .card-header {
  color: #fcc02a;
  background-image: url("btcbg_854.ad3d06a1.png");
  font-size: 13px;
}

.error {
  color: red;
}

.navbar-brand {
  color: #fcc02a;
  font-size: 24px;
  font-weight: 600;
}
/*# sourceMappingURL=index.5bf63522.css.map */
